* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .cursor {
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #F7931A;
    position: absolute;
    border-radius: 5px;
  }
  
  .cursor__follower {
    transition: transform .4s;
    width: 6px;
    height: 6px;
  }
  
  body {
    background: black;
    font-family: Arial, sans-serif;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  #overlay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  #overlay-text {
    color: #ffffff;
    background-color: rgba(128, 128, 128, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    opacity: 0;
    animation: fadeIn 2s ease forwards;
  }
  
  #overlay-text h1 {
    color: #F7931A;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  