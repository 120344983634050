/* General reset and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden; /* Prevent body scrolling */
}

/* Navigation bar styles */
.navigation-menu-and {
  align-items: center;
  background-color: #fffffffa;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 179px;
  justify-content: space-between;
  padding: 11px 24px;
  position: relative;
}

.navigation-menu-and .group {
  height: 55px;
  position: relative;
  width: 281px;
}

.navigation-menu-and .clip-path-group {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 55px;
}

.navigation-menu-and .div {
  color: #f7931a;
  font-family: "Knewave-Regular", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 63px;
  letter-spacing: -0.22px;
  line-height: 32px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.navigation-menu-and .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18px;
  justify-content: center;
  position: relative;
}

.navigation-menu-and .frame-instance {
  flex: 0 0 auto !important;
}

.property-default {
  background-color: #e92f3f;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}



#c {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure background matrix stays behind everything */
  object-fit: cover;
}

/* The overlay box container */
#overlay-container {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: auto;
  border-radius: 20%;
  max-height: 80vh;
  margin: 10vh auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Adds a glassmorphism effect */
  border-radius: 15px;
  color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3); /* Soft shadow */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Scrollable content inside the box */
#overlay-text {
  overflow-y: auto;
  max-height: 70vh;
  padding-right: 10px;
  margin-bottom: 10px;
  
}

h1 {
  font-size: 1.8em;
  margin-bottom: 15px;
  text-align: center;
  color: #ffa500; /* Orange-like color for title */
}

.section {
  margin-bottom: 20px;
}

.section p {
  font-size: 1em;
  line-height: 1.6;
  margin: 10px 0;
}

.section strong {
  color: #ffa500;
}

.section a {
  color: #00bfff;
  text-decoration: none;
}

.section a:hover {
  text-decoration: underline;
}

.warning-section {
  background-color: rgba(255, 165, 0, 0.2); /* Light orange background */
  padding: 15px;
  border-radius: 10px;
  color: #333;
  font-weight: bold;
}

hr {
  border: none;
  border-top: 1px solid #ffa500;
  margin-top: 20px;
}

ul {
  margin-left: 20px;
}

ul li {
  margin: 5px 0;
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
  #overlay-container {
    width: 85%;
    max-width: 450px;
    margin: 5vh auto;
  }

  h1 {
    font-size: 1.5em;
  }

  .section p {
    font-size: 0.9em;
  }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
  #overlay-container {
    width: 85%; /* Leave margin on the sides */
    max-width: 350px; /* Smaller max-width for better fit */
    padding: 15px;
    margin: 5vh auto;
    height: auto;
  }

  h1 {
    font-size: 1.4em; /* Slightly smaller title */
  }

  .section p {
    font-size: 0.85em;
    line-height: 1.5;
  }

  .section strong {
    font-size: 0.9em;
  }

  #overlay-text {
    max-height: 65vh; /* Reduce height on mobile */
  }
}

/* Overlay.css */

.section {
    margin-bottom: 16px; /* Adjusts space between sections */
  }
  
  ::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(255, 165, 0, 0.1); /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 165, 0, 0.8); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 165, 0, 1); /* Color of the scrollbar thumb on hover */
  }
  
